import { JobError } from 'models/jobs/jobs';

export interface JobPageParams {
  id: string;
}

export type JobStatus = 'active' | 'approved' | 'rejected' | 'removed';

export const calculateJobStatus = (
  status: string,
  success: boolean | undefined
): JobStatus => {
  if (status === 'active' || status === 'removed') {
    return status;
  }

  if (status === 'completed' && success) {
    return 'approved';
  }

  return 'rejected';
};

export const itemHasErrorOrWarning = (
  errors: JobError[],
  errWarnType: string
): { hasError: boolean; hasWarning: boolean } => {
  const error = errors.find((err) => err.type === errWarnType);

  if (!error) {
    return { hasError: false, hasWarning: false };
  }

  if (!!error.warning || !!error.suggestion || !!error.warnings) {
    return { hasError: false, hasWarning: true };
  }

  return { hasError: true, hasWarning: false };
};

// values to add to Vault

export enum StatusColors {
  Warning = '#FEC048',
  Approved = '#00CC00',
  Rejected = '#CC0000',
  NotApplicable = '#42424222'
}

export enum FontSizes {
  Medium = '2.5rem'
}

export enum FontWeights {
  SemiBold = '600'
}
