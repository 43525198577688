import React, { FC } from 'react';
import { JobStatus } from '../../utils';
import { AMLDropdowns } from '../AML/AMLDropdowns';
import { CrossCheckDropdowns } from '../CrossCheck/CrossCheckDropdowns';
import { DLVDropdown } from '../DLV/DLVDropdown';
import { FilesDropdown } from '../Files/FilesDropdown';
import { IdDocument } from '../IdDocument/IdDocument';
import { LocationDropdowns } from '../Location/LocationDropdowns';
import { FeatureNotEnabledDropdown } from '../utils';
import { CrossCheckResult } from 'models/jobs/crosscheck';
import { JobError, JobRequestParameters, JobResult } from 'models/jobs/jobs';

interface IDVDropdownsProps {
  readonly jobErrors: JobError[];
  readonly jobRequestParams: JobRequestParameters | null;
  readonly jobSid: string;
  readonly jobStatus: JobStatus;
  readonly jobResult: JobResult | undefined;
  readonly crossCheckResult: CrossCheckResult | undefined;
  readonly filesDropdownVisible: boolean;
}

export const IDVDropdowns: FC<IDVDropdownsProps> = ({
  jobErrors,
  jobRequestParams,
  jobSid,
  jobStatus,
  jobResult,
  crossCheckResult,
  filesDropdownVisible
}) => {
  const idAddress = jobResult?.idAddress;
  const ipAddress = jobResult?.ipAddress;

  return (
    <>
      {!!jobResult && (
        <IdDocument
          jobErrors={jobErrors}
          jobRequestParams={jobRequestParams}
          jobResult={jobResult}
          jobStatus={jobStatus}
        />
      )}
      {jobResult?.featuresEnabled?.crosscheckEnabled ? (
        <CrossCheckDropdowns
          crossCheckResult={crossCheckResult}
          jobErrors={jobErrors}
          jobStatus={jobStatus}
        />
      ) : (
        <FeatureNotEnabledDropdown
          title="CrossCheck"
          url="https://docs.vouched.id/docs/crosscheck-2"
        />
      )}
      {jobResult?.dlv?.dlvEnabled ? (
        <DLVDropdown
          dlvJobResult={jobResult.dlv}
          dlvSubmittedData={{
            id: jobResult.id,
            state: jobResult.state,
            lastName: jobResult.lastName,
            birthDate: jobResult.birthDate,
            expirationDate: jobResult.expireDate
          }}
          jobStatus={jobStatus}
        />
      ) : (
        <FeatureNotEnabledDropdown
          title="DLV"
          url="https://docs.vouched.id/docs/dlv-1"
        />
      )}
      {jobResult?.aml ? (
        <AMLDropdowns
          isAMLOnly={false}
          jobAMLResults={jobResult.aml}
          jobErrors={jobErrors}
          jobSid={jobSid}
          jobStatus={jobStatus}
        />
      ) : (
        <FeatureNotEnabledDropdown
          title="AML"
          url="https://docs.vouched.id/docs/aml-1"
        />
      )}
      {idAddress ?? ipAddress ? (
        <LocationDropdowns
          idAddressStr={
            idAddress
              ? [
                  idAddress.streetNumber ?? '',
                  idAddress.street ?? '',
                  idAddress.city ?? '',
                  idAddress.state ?? '',
                  idAddress.country ?? ''
                ]
                  .join(' ')
                  .trim()
              : ''
          }
          ipAddressStr={
            ipAddress
              ? [
                  ipAddress.city ?? '',
                  ipAddress.state ?? '',
                  ipAddress.country ?? ''
                ]
                  .join(' ')
                  .trim()
              : ''
          }
          ipGeocode={
            ipAddress?.location
              ? {
                  lat: ipAddress.location.latitude,
                  lng: ipAddress.location.longitude
                }
              : undefined
          }
        />
      ) : (
        <FeatureNotEnabledDropdown
          title="Location"
          url="https://docs.vouched.id/docs/location-checks"
        />
      )}
      {filesDropdownVisible && <FilesDropdown jobStatus={jobStatus} />}
    </>
  );
};
