import { Box, Text, ThemeContext } from '@vouched-id/vault';
import React, { useContext, useMemo } from 'react';
import { Tooltips } from '../../tooltips';
import {
  FontSizes,
  itemHasErrorOrWarning,
  JobStatus,
  StatusColors
} from '../../utils';
import { Dropdown } from '../Dropdown';
import { DropdownItemsSection } from '../DropdownItemsSection';
import {
  addIconsToDropdownItems,
  BooleanDropdownItem,
  ConfidenceDropdownItem,
  DropdownItemTypes,
  DropdownStatusIcon
} from '../utils';
import { CrossCheckCaption } from './CrossCheckCaption';
import { CrossCheckDetailsTables } from './CrossCheckDetailsTables';
import { CrossCheckResult } from 'models/jobs/crosscheck';
import { JobError } from 'models/jobs/jobs';

interface IdentityScoreItemProps {
  readonly score: number;
  readonly text: string;
  readonly hasWarning: boolean;
}

const IdentityScoreItem: React.FC<IdentityScoreItemProps> = ({
  score,
  text,
  hasWarning
}) => {
  const scoreColor = hasWarning ? StatusColors.Warning : StatusColors.Approved;

  const { tokens } = useContext(ThemeContext).theme;
  const fontStyles = {
    fontWeight: tokens.text.weight.bold,
    fontSize: FontSizes.Medium
  };

  return (
    <Box
      alignItems="center"
      display="flex"
      style={{
        gap: '10px',
        marginY: '30px'
      }}
    >
      <Text style={fontStyles}>{text}</Text>
      <Text
        style={{
          color: scoreColor,
          ...fontStyles
        }}
      >
        {score * 100}%
      </Text>
    </Box>
  );
};

interface CrossCheckCompletedResultProps {
  readonly crossCheckResult: CrossCheckResult;
  readonly jobErrors: JobError[];
  readonly jobStatus: JobStatus;
}

export const CrossCheckCompletedResult: React.FC<
  CrossCheckCompletedResultProps
> = ({ crossCheckResult, jobErrors, jobStatus }) => {
  const { address, email, phone, ip, confidences } = crossCheckResult;
  const { identity: identityScore } = confidences;
  const addressIsValid = address?.isValid;
  const addressIsMatch = address?.isMatch;
  const phoneIsValid = phone?.isValid;
  const phoneIsMatch = phone?.isMatch;
  const emailIsValid = email?.isValid;
  const emailIsMatch = email?.isMatch;

  const { dropdownHasWarnings, dropdownHasRejections, dropdownItems } =
    useMemo(() => {
      /*
       * hasErrorOrWarning object for CrossCheck items (except Identity Score) is calculated here b/c they've never
       * existed on the job, it's just visual for now since it does not affect the overall job status
       */
      const dropdownItemsWithoutIcons: (
        | BooleanDropdownItem
        | ConfidenceDropdownItem
      )[] = [
        {
          text: 'Identity Score',
          type: DropdownItemTypes.ConfidenceDropdownItem,
          available: typeof identityScore === 'number',
          hasErrorOrWarning: itemHasErrorOrWarning(
            jobErrors,
            'CrosscheckIdentityError'
          )
        },
        {
          text: 'Address Valid',
          type: DropdownItemTypes.BooleanDropdownItem,
          value: !!addressIsValid,
          available: true,
          hasErrorOrWarning: {
            hasError: false,
            hasWarning: !addressIsValid
          },
          tooltip: Tooltips.AddressValid
        },
        {
          text: 'Address Match',
          type: DropdownItemTypes.BooleanDropdownItem,
          value: !!addressIsMatch,
          available: true,
          hasErrorOrWarning: {
            hasError: false,
            hasWarning: !addressIsMatch
          },
          tooltip: Tooltips.AddressMatch
        },
        {
          text: 'Phone Number Valid',
          type: DropdownItemTypes.BooleanDropdownItem,
          value: !!phoneIsValid,
          available: true,
          hasErrorOrWarning: {
            hasError: false,
            hasWarning: !phoneIsValid
          },
          tooltip: Tooltips.PhoneNumberValid
        },
        {
          text: 'Phone Number Match',
          type: DropdownItemTypes.BooleanDropdownItem,
          value: !!phoneIsMatch,
          available: true,
          hasErrorOrWarning: {
            hasError: false,
            hasWarning: !phoneIsMatch
          },
          tooltip: Tooltips.PhoneNumberMatch
        },
        {
          text: 'Email Valid',
          type: DropdownItemTypes.BooleanDropdownItem,
          value: !!emailIsValid,
          available: true,
          hasErrorOrWarning: {
            hasError: false,
            hasWarning: !emailIsValid
          },
          tooltip: Tooltips.EmailValid
        },
        {
          text: 'Email Match',
          type: DropdownItemTypes.BooleanDropdownItem,
          value: !!emailIsMatch,
          available: true,
          hasErrorOrWarning: {
            hasError: false,
            hasWarning: !emailIsMatch
          },
          tooltip: Tooltips.EmailMatch
        }
      ];

      if (ip) {
        const ipIsValid = ip.isValid;
        const ipDistance = ip.distance;

        dropdownItemsWithoutIcons.push(
          {
            text: 'IP Valid',
            type: DropdownItemTypes.BooleanDropdownItem,
            value: ipIsValid,
            available: true,
            hasErrorOrWarning: {
              hasError: false,
              hasWarning: !ipIsValid
            },
            tooltip: Tooltips.IpValid
          },
          {
            text: 'IP Distance',
            type: DropdownItemTypes.BooleanDropdownItem,
            value: ipDistance ? ipDistance <= 50 : false,
            available: true,
            hasErrorOrWarning: {
              hasError: false,
              hasWarning: ipDistance ? ipDistance > 50 : true
            },
            tooltip: Tooltips.IpDistance
          }
        );
      }

      return addIconsToDropdownItems(dropdownItemsWithoutIcons);
    }, [
      jobErrors,
      itemHasErrorOrWarning,
      identityScore,
      addressIsValid,
      addressIsMatch,
      phoneIsValid,
      phoneIsMatch,
      emailIsValid,
      emailIsMatch,
      ip
    ]);

  return (
    <Dropdown
      statusIcon={
        <DropdownStatusIcon
          dropdownHasRejections={dropdownHasRejections}
          dropdownHasWarnings={dropdownHasWarnings}
          jobStatus={jobStatus}
        />
      }
      title="CrossCheck"
    >
      <Box>
        <CrossCheckCaption />
        <IdentityScoreItem
          hasWarning={dropdownHasWarnings}
          score={identityScore}
          text="Identity Score"
        />
        <DropdownItemsSection
          dropdownItems={dropdownItems.filter(
            (item) => item.text !== 'Identity Score'
          )}
          table={false}
        />
        <CrossCheckDetailsTables
          address={address}
          email={email}
          ip={ip}
          phone={phone}
        />
      </Box>
    </Dropdown>
  );
};
