import React, { FC } from 'react';
import { useLocation } from 'react-router';
import { JobStatus } from '../utils';
import { AMLDropdowns } from './AML/AMLDropdowns';
import { CrossCheckDropdowns } from './CrossCheck/CrossCheckDropdowns';
import { DOBDropdowns } from './DOB/DOBDropdowns';
import { DeleteDropdown } from './Delete/DeleteDropdown';
import { IDVDropdowns } from './IDV/IDVDropdowns';
import { ECBSVDropdowns } from './SSN/ECBSV/ECBSVDropdowns';
import { PrivateSSNDropdowns } from './SSN/PrivateSSN/PrivateSSNDropdowns';
import { JobType } from 'components/account/jobs/job_type';
import { CrossCheckResult } from 'models/jobs/crosscheck';
import { JobError, JobRequestParameters, JobResult } from 'models/jobs/jobs';
import {
  ACCOUNT_ADMIN_PERMISSIONS,
  DOWNLOADER_PERMISSIONS,
  isAuthorized
} from 'security/authorization';
import { useGlobalState } from 'store/reducers/reducer';

interface JobResultDropdownsProps {
  readonly jobErrors: JobError[];
  readonly jobRequestParams: JobRequestParameters | null;
  readonly jobSid: string;
  readonly jobStatus: JobStatus;
  readonly jobResult: JobResult | undefined;
  readonly jobType: JobType;
  readonly crossCheckResult: CrossCheckResult | undefined;
}

export const JobResultDropdowns: FC<JobResultDropdownsProps> = ({
  jobErrors,
  jobRequestParams,
  jobSid,
  jobStatus,
  jobResult,
  jobType,
  crossCheckResult
}) => {
  const { user } = useGlobalState().state;
  const isAdminRoute = useLocation().pathname.includes('/admin');
  const filesDropdownVisible =
    !isAdminRoute && isAuthorized(user!, DOWNLOADER_PERMISSIONS);

  const deleteDropdownVisible =
    !isAdminRoute && isAuthorized(user!, ACCOUNT_ADMIN_PERMISSIONS);

  const getDropdowns = (type: JobType) => {
    switch (type) {
      case JobType.idVerification:
      case JobType.idReverification:
        return (
          <IDVDropdowns
            crossCheckResult={crossCheckResult}
            filesDropdownVisible={filesDropdownVisible}
            jobErrors={jobErrors}
            jobRequestParams={jobRequestParams}
            jobResult={jobResult}
            jobSid={jobSid}
            jobStatus={jobStatus}
          />
        );
      case JobType.ecbsvSSN:
        return <ECBSVDropdowns jobSid={jobSid} jobStatus={jobStatus} />;
      case JobType.privateSSN:
        return <PrivateSSNDropdowns jobSid={jobSid} jobStatus={jobStatus} />;
      case JobType.dob:
        return <DOBDropdowns jobSid={jobSid} jobStatus={jobStatus} />;
      case JobType.idAml:
        return (
          <AMLDropdowns
            isAMLOnly
            jobAMLResults={jobResult?.aml}
            jobErrors={jobErrors}
            jobSid={jobSid}
            jobStatus={jobStatus}
          />
        );
      case JobType.idCrosscheck:
        return (
          <CrossCheckDropdowns
            crossCheckResult={crossCheckResult}
            jobErrors={jobErrors}
            jobStatus={jobStatus}
          />
        );
    }
  };

  return (
    <>
      {getDropdowns(jobType)}
      {deleteDropdownVisible && <DeleteDropdown />}
    </>
  );
};
