import { Text, ThemeContext } from '@vouched-id/vault';
import React, { FC, Fragment, useContext } from 'react';
import { AMLSanctionDetails } from './AMLCompletedResult';

interface AMLDetailsTablesProps {
  readonly sanctions: AMLSanctionDetails[];
}

export const AMLDetailsTables: FC<AMLDetailsTablesProps> = ({ sanctions }) => {
  const { tokens } = useContext(ThemeContext).theme;

  const tCellStyles = {
    border: `1px solid ${tokens.colors.gray300}`,
    padding: '8px',
    verticalAlign: 'center'
  };

  return (
    <table
      style={{
        borderCollapse: 'collapse',
        width: '100%',
        margin: '25px 0'
      }}
    >
      <tbody>
        <tr>
          <th
            colSpan={3}
            style={{
              padding: '10px',
              fontWeight: 'bold',
              color: 'white',
              backgroundColor: tokens.colors.purple
            }}
          >
            Matched Types
          </th>
        </tr>
        {sanctions.map((sanction) => {
          return (
            <Fragment key={sanction.name}>
              <tr>
                <td rowSpan={4} style={tCellStyles}>
                  <Text style={{ fontWeight: 'bold' }}>
                    {sanction.type.toUpperCase()}
                  </Text>
                </td>
                <td style={tCellStyles}>
                  <Text>Listed Name</Text>
                </td>
                <td style={tCellStyles}>
                  <Text>{sanction.name.toUpperCase()}</Text>
                </td>
              </tr>
              <tr>
                <td style={tCellStyles}>
                  <Text>Listed Url</Text>
                </td>
                <td style={tCellStyles}>
                  <Text>{sanction.url}</Text>
                </td>
              </tr>
              <tr>
                <td style={tCellStyles}>
                  <Text>Listed</Text>
                </td>
                <td style={tCellStyles}>
                  <Text>{sanction.dates.start}</Text>
                </td>
              </tr>
              <tr>
                <td style={tCellStyles}>
                  <Text>Listing Ended</Text>
                </td>
                <td style={tCellStyles}>
                  <Text>{sanction.dates.end}</Text>
                </td>
              </tr>
            </Fragment>
          );
        })}
      </tbody>
    </table>
  );
};
