import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import React, { FC } from 'react';
import { getGoogleMapCenter, GoogleLatLong } from './LocationUtils';

interface LocationMapProps {
  readonly idGeocode?: GoogleLatLong;
  readonly ipGeocode?: GoogleLatLong;
}

export const LocationMap: FC<LocationMapProps> = ({ idGeocode, ipGeocode }) => {
  const mapCenter =
    idGeocode && ipGeocode
      ? getGoogleMapCenter(idGeocode, ipGeocode)
      : ipGeocode ?? idGeocode!;

  const containerStyle = {
    width: '800px',
    height: '300px'
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env['REACT_APP_GOOGLE_MAPS_KEY'] ?? ''
  });

  return isLoaded ? (
    <div>
      <GoogleMap
        center={mapCenter}
        mapContainerClassName="map-container"
        mapContainerStyle={containerStyle}
        zoom={2}
      >
        {ipGeocode && <Marker position={ipGeocode} title="Device Location" />}
        {idGeocode && <Marker position={idGeocode} title="Document Location" />}
      </GoogleMap>
    </div>
  ) : null;
};
