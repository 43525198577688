import { Text } from '@vouched-id/vault';
import React, { FC } from 'react';

export const AMLCaption: FC = () => {
  return (
    <Text style={{ marginBottom: '15px' }}>
      Anti-Money Laundering (AML) is a series of checks against real-time global
      sanction lists, warnings, and proprietary databases of politically exposed
      persons.
    </Text>
  );
};
