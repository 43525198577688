export type GoogleLatLong = google.maps.LatLngLiteral;

export const distanceBetweenGoogleLatLong = (
  idGeoCode: GoogleLatLong,
  ipGeoCode: GoogleLatLong
): number => {
  /*
   * using Haversine Algorithm
   * https://en.wikipedia.org/wiki/Haversine_formula
   */
  const p = Math.PI / 180;
  const c = Math.cos;
  const diameterInMiles = 7917.5;
  const a =
    0.5 -
    c((idGeoCode.lat - ipGeoCode.lat) * p) / 2 +
    (c(ipGeoCode.lat * p) *
      c(idGeoCode.lat * p) *
      (1 - c((idGeoCode.lng - ipGeoCode.lng) * p))) /
      2;

  return diameterInMiles * Math.asin(Math.sqrt(a));
};

export const getGoogleMapCenter = (
  idGeoCode: GoogleLatLong,
  ipGeocode: GoogleLatLong
): GoogleLatLong => {
  const lat_max = Math.max(idGeoCode.lat, ipGeocode.lat);
  const long_max = Math.max(idGeoCode.lng, ipGeocode.lng);
  const lat_min = Math.min(idGeoCode.lat, ipGeocode.lat);
  const long_min = Math.min(idGeoCode.lng, ipGeocode.lng);
  return {
    lat: (lat_max + lat_min) / 2,
    lng: (long_max + long_min) / 2
  };
};
