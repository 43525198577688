import { Box, Text } from '@vouched-id/vault';
import React, { useMemo } from 'react';
import { itemHasErrorOrWarning, JobStatus } from '../../utils';
import { Dropdown } from '../Dropdown';
import {
  addIconsToDropdownItems,
  BooleanDropdownItem,
  ConfidenceDropdownItem,
  DropdownItemTypes,
  DropdownStatusIcon
} from '../utils';
import { AMLCaption } from './AMLCaption';
import { AMLDetailsTables } from './AMLDetailsTables';
import {
  getAmlCountries,
  getAmlMatchedTypes,
  getAmlMatchlists,
  getAmlPoliticalPositions
} from 'components/account/jobs/job-modal/job-modal-tabs/utils';
import { JobError } from 'models/jobs/jobs';
import { AMLData } from 'store/sagas/sagas';
import { humanDate } from 'utils/ui.utils';

export interface AMLSanctionDetails {
  dates: {
    start: string;
    end: string;
  };
  name: string;
  type: string;
  url: string;
}

interface AMLCompletedResultProps {
  readonly amlDetailsData: AMLData;
  readonly isAMLOnly: boolean;
  readonly jobErrors: JobError[];
  readonly jobStatus: JobStatus;
}

const getHits = <T,>(matches: Record<string, T[]> | undefined) => {
  if (!matches) return 0;
  let hits = 0;
  for (const match of Object.values(matches)) hits += match.length;
  return hits;
};

export const AMLCompletedResult: React.FC<AMLCompletedResultProps> = ({
  amlDetailsData,
  isAMLOnly,
  jobErrors,
  jobStatus
}) => {
  const name = amlDetailsData.submittedTerm ?? amlDetailsData.submitted_term;
  const hits = getHits(amlDetailsData.hits?.matches);
  const matchedTypes = getAmlMatchedTypes(amlDetailsData);
  const countries = getAmlCountries(amlDetailsData);
  const yearOfBirth = amlDetailsData.dob ?? 'None';
  const aka = getAmlMatchlists(amlDetailsData);
  const politicalPositions = getAmlPoliticalPositions(amlDetailsData);
  const matches = amlDetailsData.hits?.matches;
  const sanctions = useMemo(() => {
    const sanctionsArray: AMLSanctionDetails[] = [];
    if (matches) {
      for (const details of Object.values(matches)) {
        for (const detail of details) {
          sanctionsArray.push({
            dates: {
              start: detail.listing_started_utc
                ? humanDate(new Date(detail.listing_started_utc), true)!
                : 'N/A',
              end: detail.listing_ended_utc
                ? humanDate(new Date(detail.listing_ended_utc), true)!
                : 'N/A'
            },
            name: detail.name,
            url: detail.url,
            type: detail.type
          });
        }
      }
    }

    return sanctionsArray;
  }, [matches, humanDate]);

  const { dropdownHasWarnings, dropdownHasRejections } = useMemo(() => {
    const dropdownItemsWithoutIcons: (
      | BooleanDropdownItem
      | ConfidenceDropdownItem
    )[] = [
      {
        text: 'AML',
        type: DropdownItemTypes.ConfidenceDropdownItem,
        available: true,
        hasErrorOrWarning: itemHasErrorOrWarning(jobErrors, 'AMLError')
      }
    ];

    return addIconsToDropdownItems(dropdownItemsWithoutIcons);
  }, [jobErrors, itemHasErrorOrWarning]);

  return (
    <Dropdown
      statusIcon={
        isAMLOnly ? undefined : (
          <DropdownStatusIcon
            dropdownHasRejections={dropdownHasRejections}
            dropdownHasWarnings={dropdownHasWarnings}
            jobStatus={jobStatus}
          />
        )
      }
      title="AML"
    >
      <Box>
        <AMLCaption />
        <Box
          display="grid"
          marginBottom="spacing.default"
          style={{
            gridTemplateColumns: 'auto 1fr',
            justifyItems: 'start',
            gridGap: '15px'
          }}
        >
          <Text style={{ fontWeight: 'bold' }}>{`Name: `}</Text>
          <Text>{name ?? 'N/A'}</Text>
          <Text style={{ fontWeight: 'bold' }}>{`Hits: `}</Text>
          <Text style={{ ...(hits && { fontWeight: 'bold', color: 'red' }) }}>
            {hits}
          </Text>
          <Text style={{ fontWeight: 'bold' }}>{`Matched Types: `}</Text>
          <Text
            style={{
              ...(matchedTypes !== 'None' && {
                fontWeight: 'bold',
                color: 'red'
              })
            }}
          >
            {matchedTypes}
          </Text>
          <Text style={{ fontWeight: 'bold' }}>{`Countries: `}</Text>
          <Text>{countries}</Text>
          <Text style={{ fontWeight: 'bold' }}>{`Year Of Birth: `}</Text>
          <Text>{yearOfBirth}</Text>
          <Text style={{ fontWeight: 'bold' }}>{`AKA: `}</Text>
          <Text>{aka}</Text>
          <Text style={{ fontWeight: 'bold' }}>{`Political Positions: `}</Text>
          <Text>{politicalPositions}</Text>
        </Box>
        {sanctions.length > 0 ? (
          <AMLDetailsTables sanctions={sanctions} />
        ) : null}
      </Box>
    </Dropdown>
  );
};
